import React, { useState, useCallback } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import Popup from "../../componentForPages/Popup/Popup"

import {
  Title,
  Dropdown,
  Icon,
  Button,
  ArrowDown,
} from "../../StyleComponents/styles"

import ScrollWindowList from "./components/scrollReactWindow"

const Pagination = React.lazy(() =>
  import("../../components/Pagination/Pagination")
)
const DropDownList = React.lazy(() => import("./components/DropDown"))

const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/emoji-copy-and-paste",
  "/text-art",
]
const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "Html Entity", link: "/html-entity/" },
]

function EmojiNamesList(props) {
  const { currentPage, totalPages, pageItems, allPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const prevPage =
    currentPage - 1 === 1
      ? "/html-entity/"
      : `/html-entity/${(currentPage - 1).toString()}/`
  const nextPage = `/html-entity/${(currentPage + 1).toString()}/`

  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    e.target.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
      setTimeout(function () {
        setShow(false)
      }, 1500)
    }
  }, [])

  return (
    <Layout location={props.location}>
      <SEO
        title={`Html Entity and Characters copy paste `}
        description={`Html Entity Characters Entity Decimal Hexadecimal and copy paste Characters.`}
        keywords={[
          "Characters Entity, Html Entity, Characters Hexadecimal, copy paste Characters, text characters, copy paste ascii, html entity code",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title id="listOfEmoji">Html Entity</Title>
        <br />
        {show && (
          <Popup
            title={emoji + " have been copied"}
            emoji={emoji}
            close={() => setShow(false)}
          />
        )}

        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Characters Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownList list={allPages} />
            </React.Suspense>
          )}
        </Dropdown>

        <br />
        <ScrollWindowList pageItems={pageItems} mycopy={mycopy} />
        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
              pagesLink="html-entity"
            />
          </React.Suspense>
        )}
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmojiNamesList
